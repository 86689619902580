<template>
  <!-- Boards Public View -->
  <div class="w-full h-full  relative bg-none">
    <!-- Whitelabel -->
    <transition name="fadeHeader" mode="out-in">
      <WhiteLabelHeader v-if="boardCreator !== null" :board-team="boardCreator" :shared-by="sharedByUser" :board="board"
        :ads="advertisements" />
      <WhiteLabelHeader v-else-if="boardCreator !== null && !boardCreator?.whiteLabel" :board-team="boardCreator"
        :shared-by="sharedByUser" :show-default="true" />
    </transition>


    <!-- Brand -->
    <!-- <a v-else href="https://foreplay.co">
      <img src="../../assets/images/brief-foreplay-icon.svg" alt="" class="filter invert ml-5 mt-4 h-10">
    </a> -->
    <MainLayout v-scroll="onScroll" scroll-toolbar aditional-classes="ml-4"
      style="height: calc(100vh - 88px); width: calc(100vw - 30px); z-index: 99; position: relative">
      <!-- Toolbar -->
      <template #toolbar>
        <div class="flex items-center justify-between p-5">
          <div>
            <div class="flex items-center gap-3">
              <BaseText type="heading" size="md" class="text-text-muted">
                {{ board?.name }}
              </BaseText>
              <!-- <div class="bg-neutral-50 rounded-md py-0.5 px-1.5"> -->
                <!-- <BaseText size="sm" class="text-text-muted">
                  {{ numOfAds }} Ads
                </BaseText> -->
              <!-- </div> -->
            </div>
          </div>
        </div>
      </template>

      <template #filter>
        <div ref="filterBar" class="border hidden sm:block border-border-normal bg-white bg-opacity-90"
          style="backdrop-filter: blur(6px)">
          <FilterTopBar :selected-formats.sync="selectedFormats" :selected-platforms.sync="selectedPlatforms"
            :selected-niches.sync="selectedNiches" :selected-languages.sync="selectedLanguages"
            :selected-date-range.sync="selectedDateRange" :sort-order.sync="sortOrder" :text-search.sync="textSearch"
            :live-status.sync="liveStatus" @change="fetchAdvertisements" />
        </div>
      </template>

      <template #content>
        <!-- Loading State -->
        <BaseLoadingLogo v-if="loadingAdvertisements || loadingSubscription" class="mt-24" />

        <!-- Empty State -->
        <div v-else-if="!advertisements.length" class="flex flex-col items-center mt-20">
          <img src="../../assets/images/empty-single-library.gif"
            class="border border-gray-400 rounded mx-auto object-cover" style="width: 400px; height: 300px">

          <div class="text-lg font-bold my-4">
            This board is empty...
          </div>

          <div class="text-center text-gray-700">
            This board has been shared with you but doesn't

            <br>

            have any ads yet.
          </div>
        </div>
        <div v-else-if="!userSubscribed" class="flex flex-col items-center mt-20">
          <div class="text-lg font-bold my-4">
            The owner of this board’s foreplay account has been cancelled
          </div>
        </div>
        <!-- Ads List -->
        <AdvertisementList v-else hide-boards :advertisements="advertisements" :shared-board="true" />
        <infinite-loading v-if="advertisements.length && !loadingAdvertisements" @infinite="getMoreAds">
          <div slot="spinner">
            <BaseLoadingLogo :margin="2" />
          </div>
          <div slot="no-more" />
          <div slot="no-results" />
        </infinite-loading>
      </template>
    </MainLayout>
  </div>
</template>

<script>
import MainLayout from '../../layouts/MainLayout'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import FirebaseAPI from '@/api/firebase'
import ForeplayAPI from '@/api/foreplayServer'
import AdvertisementList from '../../components/advertisements/AdvertisementList'
import InfiniteLoading from 'vue-infinite-loading'

import FilterTopBar from '../../components/search/FilterTopBar.vue'
import WhiteLabelHeader from '../../components/account/whiteLabel/WhiteLabelHeader.vue'

export default {
  name: 'BoardsPublicView',
  components: {
    AdvertisementList,
    MainLayout,
    InfiniteLoading,
    FilterTopBar,
    WhiteLabelHeader
  },
  data() {
    return {
      board: {},
      advertisements: [],
      loadingAdvertisements: true,
      loadingSubscription: true,
      userSubscribed: true,
      boardCreator: null,
      sharedByUser: null,
      // numOfAds: 0,

      lastDocId: null,
      selectedFormats: [],
      selectedPlatforms: [],
      selectedNiches: [],
      selectedBrands: [],
      selectedTags: [],
      selectedLanguages: [],
      selectedDateRange: {
        start: null,
        end: null
      },
      liveStatus: [],
      sortOrder: [{ name: 'Newest', value: 'desc' }],
      docOrder: 'desc',
      textSearch: ''
    }
  },
  computed: {
    ...mapGetters('BoardsModule', ['getBoards']),
    ...mapGetters('AuthModule', ['getUser', 'getTeam']),
  },
  async mounted() {
    await this.fetchAdvertisements()
    await this.checkIfUserSubscribed()

    // Since there is no navbar, we need to initialize some Vuex state here
    await this.fetchBoards()
    await this.fetchFolders()
    await this.SET_STARRED_BOARDS(
      this.getBoards.filter((board) =>
        this.getUser.starredBoards?.includes(board.id)
      )
    )

    // Set initial top bar styles
    if (this.$refs.filterBar) {
      this.$refs.filterBar.style.position = 'sticky'
      this.$refs.filterBar.style.top = '16px'
      this.$refs.filterBar.style.zIndex = '50000'
    }



    document.title = `${this.board?.name} | Foreplay Share Board`
  },
  methods: {
    ...mapActions('BoardsModule', ['fetchBoards', 'fetchFolders']),
    ...mapMutations('BoardsModule', ['SET_BOARDS']),
    ...mapMutations('AuthModule', ['SET_STARRED_BOARDS']),
    onScroll(e, position) {
      const marginValue =
        position.scrollTop / 6 < 16 ? position.scrollTop / 6 : 16
      const rounding =
        position.scrollTop / 10 < 5 ? position.scrollTop / 10 : 5

      this.$refs.filterBar.style.marginLeft = `${marginValue}px`
      this.$refs.filterBar.style.marginRight = `${marginValue}px`
      this.$refs.filterBar.style.marginTop = `${marginValue}px`
      this.$refs.filterBar.style.borderRadius = `${rounding}px`

      const shadowSection = (maxValue) => {
        return position.scrollTop / 15 < maxValue
          ? position.scrollTop / 15
          : maxValue
      }

      if (position.scrollTop) {
        this.$refs.filterBar.style.boxShadow = `rgba(0, 0, 0, 0.08) 0px ${shadowSection(
          15
        )}px ${shadowSection(55)}px, rgba(0, 0, 0, 0.08) 0px -${shadowSection(
          7
        )}px ${shadowSection(
          10
        )}px, rgba(0, 0, 0, 0.08) 0px 4px 6px, rgba(0, 0, 0, 0.08) 0px ${shadowSection(
          7
        )}px ${shadowSection(13)}px, rgba(0, 0, 0, 0.08) 0px -${shadowSection(
          3
        )}px ${shadowSection(5)}px`
        // this.$refs.filterBar.style.boxShadow = `rgba(58, 111, 251, 0.4) -${shadowSection(5)}px ${shadowSection(5)}px, rgba(58, 111, 251, 0.3) -${shadowSection(10)}px ${shadowSection(10)}px, rgba(58, 111, 251, 0.2) -${shadowSection(15)}px ${shadowSection(15)}px, rgba(58, 111, 251, 0.1) -${shadowSection(20)}px ${shadowSection(20)}px, rgba(58, 111, 251, 0.05) -${shadowSection(25)}px ${shadowSection(25)}px`
      } else {
        this.$refs.filterBar.style.boxShadow = null
      }

      // This may have existed for a reason, no one knows what, but it was causing a z-index issue
      // if (marginValue === 16) {
      //   this.$refs.filterBar.style.position = 'sticky'
      //   this.$refs.filterBar.style.top = '16px'
      //   this.$refs.filterBar.style.zIndex = '50000'
      // }
    },
    async checkIfUserSubscribed() {
      // add team check
      const isUserSubscribed = await FirebaseAPI.Users.checkUserSubscription(
        this.board.created_by
      )
      let isTeamSubscribed
      if (this.board.teamId && !isUserSubscribed) {
        isTeamSubscribed = await FirebaseAPI.Users.checkTeamSubscription(
          this.board.teamId
        )
      }

      this.userSubscribed = isUserSubscribed || isTeamSubscribed
      this.loadingSubscription = false
    },
    // Go to Signup
    goToSignup() {
      this.SET_BOARDS([])
      this.$router.push({ name: 'LoginView' })
    },
    // Fetch Advertisements
    async getMoreAds($state) {
      const { results, nextPage } = await ForeplayAPI.Ads.getPublicBoardAds(
        this.board.id,
        this.lastDocId,
        {
          textSearch: this.textSearch,
          sort: this.sortOrder[0].value,
          orFilters: {
            formats: this.selectedFormats,
            niches: this.selectedNiches,
            publisher_platform: this.selectedPlatforms,
            tags: this.selectedTags,
            liveStatus: this.liveStatus,
            languages: this.selectedLanguages,
            brands: this.selectedBrands
          }
        }
      )

      this.lastDocId = nextPage

      if (!this.lastDocId) {
        $state.complete()
        return
      }

      this.advertisements = this.advertisements.concat(results)
      $state.loaded()
    },
    async fetchAdvertisements(load = true, refresh = false) {
      this.loadingAdvertisements = load
      this.advertisements = []

      if (refresh) {
        this.lastDocId = null
      }

      const savedBetweenStart = +new Date(this.selectedDateRange?.start) || null
      const savedBetweenEnd = +new Date(this.selectedDateRange?.end) || null

      try {
        const sharedBoard = (await FirebaseAPI.Boards.getSharedByID(this.$route.params.id)).data()
        let boardSnapshot;
        // Backward compatible, handles new and old share board routes
        if (sharedBoard) {
          boardSnapshot = (
            await FirebaseAPI.Boards.getByID(sharedBoard.board_id)
          )
          let userId = boardSnapshot.created_by
          this.sharedByUser = await FirebaseAPI.Users.getById(userId)
        }
        else {
          boardSnapshot = await FirebaseAPI.Boards.getByID(this.$route.params.id)
        }
        const boardData = boardSnapshot;
        // Get team that created the board
        const boardTeamData = await FirebaseAPI.Teams.getByID(boardData.teamId)
        // Get total ad count for board
        // const adCounts = await ForeplayAPI.Ads.getSharedBoardsAdCount(boardData.created_by, boardData?.teamId ?? undefined)
        // this.numOfAds = adCounts.find(adCount => adCount.key === boardData.id)?.doc_count || 0

        const board = { ...boardData, id: boardSnapshot.id }
        this.board = board
        this.boardCreator = boardTeamData
        if (board.id) {
          // Track Board Shared
          window.analytics.track('Board Shared Viewed', {
            boardName: board.name,
            boardId: board.id
          })
          const { results, nextPage } = await ForeplayAPI.Ads.getPublicBoardAds(
            board.id,
            null,
            {
              savedBetweenStart,
              savedBetweenEnd,
              textSearch: this.textSearch,
              sort: this.sortOrder[0].value,
              orFilters: {
                formats: this.selectedFormats,
                niches: this.selectedNiches,
                publisher_platform: this.selectedPlatforms,
                tags: this.selectedTags,
                liveStatus: this.liveStatus,
                languages: this.selectedLanguages,
                brands: this.selectedBrands
              }
            }
          )

          this.lastDocId = nextPage

          this.advertisements = results
        } else {
          this.$router.push({ name: 'LibraryView' })

          this.$showAlert({
            message: 'Board not found',
            type: 'error'
          })
        }
      } catch (e) {
        console.log(e)
        this.$showAlert({
          message: e,
          type: 'error'
        })
      } finally {
        this.loadingAdvertisements = false
        this.infiniteId += 1

        setTimeout(() => {
          window.dispatchEvent(new Event('resize'))
        }, 100)
      }
    },
  }
}
</script>

<style scoped>
.fadeHeader-enter-active,
.fadeHeader-leave-active {
  transition: all 0.2s ease-out;
  opacity: 1;
}

.fadeHeader-enter,
.fadeHeader-leave-to {
  opacity: 0;
}
</style>
